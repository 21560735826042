export const select_pans = [
   { id: 1, panName: "География" },
   { id: 2, panName: "Биология" },
   { id: 3, panName: "Химия" },
   { id: 4, panName: "Қазақстан Тарихы" },
   { id: 6, panName: "Математикалық сауаттылық" },
   { id: 7, panName: "Оқу сауаттылығы" },
   { id: 8, panName: "Математика" },
   { id: 9, panName: "Физика" },
   { id: 10, panName: "Дүниежүзі тарихы" },
   { id: 11, panName: "Құқық негіздері" },
   { id: 12, panName: "Қазақ тілі" },
   { id: 13, panName: "Қазақ әдебиеті" },
   { id: 14, panName: "Орыс тілі" },
   { id: 15, panName: "Ағылшын" },
   // {id: 16, panName: 'География NEW'}
   { id: 17, panName: "Информатика" },
   { id: 19, panName: "Бастауыш сынып" },
];
