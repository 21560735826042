import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ITest, ITest5, ITestTeacher } from "../../../../utils/models/test.models";
import { BASE_URL, BASE_URL_ANALIZ, BASE_URL_NUSKA, BASE_URL_OFFICE } from "../../../../utils/constants/base_url";
import { setInitialTestAnswerItemsNuska, setTestAnswerItems5 } from "../../test_answer/testAnswer.slice";
import { RootState } from "../../../store";
import { ITestNuska } from "../../../../utils/models/nuska.models";
import { setTestDataType } from "../test.slice";

export const loadTestTeacher = createAsyncThunk("test/teacher", async (params: { Id: string }) => {
   try {
      const { data } = await axios.post<ITestTeacher>(`${BASE_URL}/api/kval/create`, params);

      if (!data || typeof data === "string") {
         console.log("Error startHandler(): Result is null or string!");
         throw new Error("Қателік кетті! Қайталаңыз");
      }
      if (!data.state) {
         throw new Error("Сізде тест тапсыруға доступ жоқ!");
      }
      return data;
   } catch (e: any) {
      throw new Error(e.message);
   }
});
export const loadTest = createAsyncThunk("test/one", async (params: { PanId: number; UserId: string }) => {
   try {
      const { data } = await axios.post<ITest>(`${BASE_URL}/api/test/one`, params);
      if (!data) throw new Error("Result is null");
      return data;
   } catch (e: any) {
      throw new Error(e.message);
   }
});

export const loadTest5 = createAsyncThunk<
   ITest5,
   { type: "five" | "analiz" | "office"; body: { FiveId?: number; UserId: string; Pan1: number; Pan2: number } },
   { state: RootState }
>("test/five", async (params, { dispatch }) => {
   try {
      const api = {
         five: `${BASE_URL}/api/five/build`,
         analiz: `${BASE_URL_ANALIZ}/api/analiz/build`,
         office: `${BASE_URL_OFFICE}/api/office/build`,
      };
      const { data } = await axios.post<ITest5>(api[params.type], params.body);
      if (!data) throw new Error("Result is null");

      data.items.map((item) =>
         dispatch(
            setTestAnswerItems5({
               testId: item.id,
               panId: item.panId,
            })
         )
      );
      dispatch(setTestDataType(params.type));

      return data;
   } catch (e: any) {
      throw new Error(e.message);
   }
});

export const loadTestNuska = createAsyncThunk<
   ITestNuska,
   { NuskaId: number; UserId: string; Pan1: number; Pan2: number },
   { state: RootState }
>("test/nuska", async (params, { dispatch }) => {
   try {
      const { data } = await axios.post<ITestNuska>(`${BASE_URL_NUSKA}/quiz/post`, params);

      document.getElementById("close_fio")?.click(); // Close Fio Modal

      if (!data) throw new Error("Result is null");

      data.items.map((item) =>
         dispatch(
            setInitialTestAnswerItemsNuska({
               PanId: item.panId,
               Items: [],
            })
         )
      );
      dispatch(setTestDataType("nuska"));

      return data;
   } catch (e: any) {
      throw new Error(e.message);
   }
});
