const authScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/vendors/bootstrap-select/bootstrap-select.min.js",
   "/assets/vendors/bootstrap-touchspin/jquery.bootstrap-touchspin.js",
   "/assets/vendors/magnific-popup/magnific-popup.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/imagesloaded/imagesloaded.js",
   "/assets/vendors/masonry/masonry.js",
   "/assets/vendors/masonry/filter.js",
   "/assets/vendors/owl-carousel/owl.carousel.js",
   "/assets/js/functions.js",
   "/assets/js/contact.js",
];
const contentScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/scroll/scrollbar.min.js",
   "/assets/vendors/calendar/moment.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/js/admin.js",
   "/assets/js/selectScript.js",
];
const playerScriptUrls = ["https://cdn.plyr.io/3.5.6/plyr.js", "/assets/js/playerScript.js"];
const mathScripts = [
   "https://polyfill.io/v3/polyfill.min.js?features=es6",
   "https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js",
];

const remScript = () => {
   const scripts = Array.from(document.getElementsByTagName("script"));
   scripts.forEach((script) => {
      if (!script.src.includes("bundle.js")) {
         script.remove();
      }
   });
};

const createScript = (url: string) => {
   const script = document.createElement("script");
   script.src = url;
   script.async = true;
   if (url.includes("mathjax")) {
      script.id = "MathJax-script";
   }
   return script;
};

const importScript = (url: string) => {
   const script = createScript(url);
   document.body.appendChild(script);
   return script;
};

const importScripts = (urls: string[]) => {
   remScript();
   urls.forEach((url) => importScript(url));
};

export const importAuthScripts = () => importScripts(authScriptUrls);
export const importContentScripts = () => importScripts(contentScriptUrls);
export const importPlayerScripts = () => importScripts(playerScriptUrls);
export const importMathScripts = () => importScripts(mathScripts);

// <?xml version="1.0" encoding="UTF-8"?>
// <configuration>
//     <system.webServer>
// 		<rewrite>
// 		  <rules>
// 			<rule name="ReactRouter Routes" stopProcessing="true">
// 			  <match url=".*" />
// 			  <conditions logicalGrouping="MatchAll">
// 				<add input="{REQUEST_FILENAME}" matchType="IsFile" negate="true" />
// 			  </conditions>
// 			  <action type="Rewrite" url="index.html" />
// 			</rule>

// 		  </rules>
// 		</rewrite>
//     </system.webServer>
// </configuration>
